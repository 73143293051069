module.exports = {
	  server: "cw preprod",
	  serverTokenUrl: "https://s360app.mycw.in/cw-identity/oauth/token",
	  serverUrl: "https://s360app.mycw.in/cw-retail/graphql",
	  CWCoreServicesUrl: "https://sapp.mycw.in/services/graphql",
	  edcUrl: "http://localhost:80/innoweb/api/MSwipe",
	  //server: "cw opt",
	  //serverTokenUrl: "https://opt-tserv03.exceloid.in/cw-identity/oauth/token",
	  //serverUrl: "https://opt-tserv03.exceloid.in/cw-retail/graphql",
	  //CWCoreServicesUrl: "https://opt-tserv03.exceloid.in/back-office-2.0/graphql",
	  innovitiPaymentCodes: {
		      Card: 0,
		      GPay: 86,
		      Sodexo: 90,
		      UPI: 86,
		    },
	  //fileDownloadUrl: 'https://opt-tserv03.exceloid.in:8445/'
	  fileDownloadUrl: 'https://sapp.mycw.in:8443/'
};
