import io from "socket.io-client";

//cw opt
//const socket = io("https://mw-app.cwsuite.com/");

//cw preprod
const socket = io("https://mw-pos-test.mycw.in/");

const sendOrder = (order) => {
  socket.emit("newOrder", order);
};

export { socket, sendOrder };
