import { currencyTypes } from "../../constants/taxCurrencies";
import db from "../../database";

export const htmlPrint = async(printReciptData, receiptType) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const printWindow = window.open("", "printWindow", "width=0,height=0");
  let printData = tillData.tillAccess.cwrTill.printTemplate.htmlcode2;
  let roundOffValue = await db.currency.where("csCurrencyId").equals(tillData.tillAccess.csBunit.currencies[0].csCurrencyId).toArray();

  const addedToCart = printReciptData.items;
  const taxKeyValue = [];
  const taxType = [];

  for (let i = 0; i < addedToCart.length; i += 1) {
    let taxRate = addedToCart[i].taxRate;
    if (taxType.indexOf(taxRate) !== -1) {
      let taxVal = currenciesList[0].isoCode === "INR" ? addedToCart[i].taxAmount / 2 : addedToCart[i].taxAmount;
      for (let j = 0; j < taxKeyValue.length; j += 1) {
        const keys = Object.keys(taxKeyValue[j]);
        if (keys[0].toString() === taxRate.toString()) {
          taxKeyValue[j][taxRate] = taxKeyValue[j][taxRate] + taxVal;
        }
      }
    } else {
      taxType.push(taxRate);
      let taxVal = currenciesList[0].isoCode === "INR" ? addedToCart[i].taxAmount / 2 : addedToCart[i].taxAmount;
      taxKeyValue.push({ [taxRate]: taxVal });
    }
  }

  let taxTypeCurrencyWise = currencyTypes.filter((item, index) => item.type === currenciesList[0].isoCode);

  let taxAmountBreakup = "";
  taxKeyValue.map((value, i) =>
    taxTypeCurrencyWise[0]?.taxType?.map(
      (item) =>
        (taxAmountBreakup += `<tr>
       <td>${item} ${item === "CGST" || item === "SGST" ? taxType[i] / 2 : taxType[i]}%</td>
       <td>${currenciesList[0].isoCode === "USD" ? parseFloat(parseFloat(value[taxType[i]]).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(parseFloat(value[taxType[i]]).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat(parseFloat(value[taxType[i]]).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat(parseFloat(value[taxType[i]]).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat(parseFloat(value[taxType[i]]).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(parseFloat(value[taxType[i]]).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}</td>
     </tr>`)
    )
  );

//   taxKeyValue.map((value, i) => {
//     return (taxAmountBreakup += `<tr>
//        <td>CGST ${taxType[i] / 2}%</td>
//        <td>${value[taxType[i]].toFixed(roundOffValue[0].prcPrecision)}</td>
//      </tr>
//      <tr>
//        <td>SGST ${taxType[i] / 2}%</td>
//        <td>${value[taxType[i]].toFixed(roundOffValue[0].prcPrecision)}</td>
//      </tr>`);
//   });

  // printReciptData.items.map((data) => {
  //   return (printItems += `<tr class="left">
  //       <td colspan="2">${data.shortDescription === null ? data.name : data.shortDescription}</td>
  //       <td>${parseFloat(data.weight).toFixed(roundOffValue[0].prcPrecision)}</td>
  //       <td>${parseFloat(data.salePrice).toFixed(roundOffValue[0].prcPrecision)}</td>
  //    </tr>
  //    <tr class="left">
  //       <td>${data.hsncode === null ? "-" : data.hsncode}</td>
  //       <td>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(roundOffValue[0].prcPrecision)}</td>
  //       <td>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(roundOffValue[0].prcPrecision)}</td>
  //       <td>${parseFloat(data.nettotal).toFixed(roundOffValue[0].prcPrecision)}</td>
  //    </tr>
  //    `);
  // });

	let printItems = "";
	printReciptData.items.map((data) => {
    return (printItems += `<tr class="left">
        <td colspan="2">${data.shortDescription === null ? data.name : data.shortDescription}</td>
        <td>${parseFloat(data.weight).toFixed(roundOffValue[0].prcPrecision)}</td>
        <td>${currenciesList[0].isoCode === "USD" ? parseFloat(data.salePrice.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(data.salePrice.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat(data.salePrice.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") :  currenciesList[0].isoCode === "EUR" ? parseFloat(data.salePrice.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat(data.salePrice.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(data.salePrice.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}</td>
     </tr>
     <tr class="left">
        <td colspan="${taxTypeCurrencyWise[0]?.taxType?.filter(item => item === "CGST" || item === "SGST")?.length === 0 ? 2 : 0}">${data.hsncode === null ? data.canceled === "Y" ? "Cancelled" :"-" : data.hsncode}</td>
        ${taxTypeCurrencyWise[0]?.taxType !==undefined ?  taxTypeCurrencyWise[0]?.taxType?.map(item => item === "CGST" || item === "SGST" 
					? `<td>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(roundOffValue[0].prcPrecision)}</td>`
					: `<td>${parseFloat(parseFloat(data.taxAmount)).toFixed(roundOffValue[0].prcPrecision)}</td>`
				) : `<td></td> <td></td>`}
        <td>${currenciesList[0].isoCode === "USD" ? parseFloat(data.nettotal.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(data.nettotal.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat(data.nettotal.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat(data.nettotal.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat(data.nettotal.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(data.nettotal.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}</td>
     </tr>
     `);
  });


  let printPayments = "";
  printReciptData.payments.map((data) => {
    return (printPayments += `<tr>
           <td>${data.name}</td>
           <td>${currenciesList[0].isoCode === "USD" ? parseFloat(parseFloat(data.amount).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(parseFloat(data.amount).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat(parseFloat(data.amount).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") :  currenciesList[0].isoCode === "EUR" ? parseFloat(parseFloat(data.amount).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat(parseFloat(data.amount).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(parseFloat(data.amount).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}</td>
         </tr>`);
  });

  // tempPrintData start
  /*   let printData = `<!DOCTYPE html>
  <html lang="en">
     <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Receipt</title>
        <style>
           * {
             font-size: 12px;
             font-family: "Times New Roman";
           }
           .centered {
             text-align: center;
           }
           .left {
             text-align: left;
           }
           .right {
             text-align: right;
           }
           .center {
             margin-left: auto;
             margin-right: auto;
           }
           .sp-top {
               border-top: 1px solid black;
               border-collapse: collapse;
           }
           .sp-btm {
               border-bottom: 1px solid black;
               border-collapse: collapse;
           }
        </style>
     </head>
     <body>
        <div style="width: 100%;">
           <h1 style="font-size: 30px; font-weight: bold; text-align: center; margin-bottom: 0px;">CW Suite</h1>
           <!-- <img alt="CW ERP" style ="display: block; margin-left: auto; margin-right: auto; height: 50px; width: 100px;" src="https://cw.solutions/wp-content/uploads/2020/02/CW_LOGO_90.jpg"> -->
           <br />
           <p class="centered" style="margin-top: 2px; margin-bottom: 2px;">
              @@csBunitCustomerAddressFulladdress@@
              <br>Phone: @@csBunitCustomerAddressPhone@@
           </p>
           <hr style="border-top: 1px solid #000000;" />
           <p class="centered" style="margin-top: 2px; margin-bottom: 2px;"><b>@@receiptType@@</b></p>
           <table style="width: 100%; margin-left: auto; margin-right: auto;">
              <tr>
                 <td>Customer: @@printReciptDataCustomerName@@</td>
                 <td>Cashier: @@username@@</td>
              </tr>
              <tr>
                 <td>@@printReciptDataCustomerMobileNo@@</td>
                 <td></td>
              </tr>
              <tr>
                 <td>Document: @@printReciptDataDocumentno@@</td>
                 <td>Date: @@date@@</td>
              </tr>
           </table>
           <table style="width: 100%;">
              <thead>
                 <tr class="left">
                    <th class="sp-top" colspan="2">NAME</th>
                    <th class="sp-top">QTY</th>
                    <th class="sp-top">PRICE</th>
                 </tr>
                 <tr class="left">
                  <th class="sp-btm">HSN</th>
                  <th class="sp-btm">CGST</th>
                  <th class="sp-btm">SGST</th>
                  <th class="sp-btm">AMOUNT</th>
                 </tr>
              </thead>
              <tbody>
                 @@printReciptDataItems@@
              </tbody>
           </table>
           <hr style="border-top: 1px solid #000000;" />
           <table style="width: 100%;">
              <tr>
                 <td>Total Items: @@printReciptDataItemsLength@@</td>
                 <td>Total Quantity: @@printReciptDataTotalQty@@</td>
              </tr>
           </table>
           <hr style="border-top: 1px solid #000000;" />
           <table style="width: 100%;">
           @@taxAmountBreakup@@
              <tr>
                 <td>Net Amount:</td>
                 <td>@@netAmount@@</td>
              </tr>
              <tr>
                 <td>Tax Amount:</td>
                 <td>@@taxAmount@@</td>
              </tr>
              <tr>
                 <td>Discount:</td>
                 <td>@@discountAmount@@</td>
              </tr>
              <tr>
                 <td>Round Off:</td>
                 <td>@@roundOff@@</td>
              </tr>
              <tr>
                 <td><b>Total</b></td>
                 <td><b>@@total@@</b></td>
              </tr>
           </table>
           <hr style="border-top: 1px solid #000000;" />
           <table style="width: 100%;">
              @@printReciptDataPayments@@
              <tr>
                 <td>Change</td>
                 <td>@@cashChangeAmount@@</td>
              </tr>
              <tr>
                 <td><b>Amount Received: <b></td>
                 <td><b>@@amountReceived@@</b></td>
              </tr>
           </table>
           <br/>
           <div style="text-align: center;">
           @@billDetails@@
           </div>
           <br/>
           <div style="text-align: center;">
           @@couponCode@@
           </div>
           <br/>
           <p class="centered">Thanks for your purchase!</p>
        </div>
       @@additionalBodyData@@
     </body>
  </html>`; */
  // tempPrintData end

  const customerName = printReciptData.customer.name === "Anonymous Customer" ? "" : printReciptData.customer.name;
  const customerMobile = printReciptData.customer.mobileNo === "9999999999" ? "" : printReciptData.customer.mobileNo;
  const fullAddress = tillData.tillAccess.csBunit.locations[0].fulladdress.replaceAll(", ", ",<br />");
  let couponCodeData = "";
  let billDetailsData = "";

  if (printReciptData.voucherCode !== null) {
    couponCodeData = `<p class="centered">Coupon Code</p><img src="https://api.qrserver.com/v1/create-qr-code/?data=${printReciptData.voucherCode}&amp;size=100x100" alt="CouponCode" title="" /><p>${printReciptData.voucherCode}</p>`;
  }

  // if(printReciptData.layAway === "Y"){
  const reciptQRBillDetails = `orderID%3A${printReciptData.sOrderID}%26%26billNo%3A${printReciptData.documentno}%26%26amount%3A${printReciptData.total}%26%26customer%3A${printReciptData.customer.name}%26%26`;
  billDetailsData = `<img src="https://api.qrserver.com/v1/create-qr-code/?data=${reciptQRBillDetails}&amp;size=100x100" alt="CouponCode" title="" />`;
  // }

  if (printReciptData.orderSelection) {
    printData = printData.replaceAll("@@ordertype@@", `${printReciptData.orderSelection}`);
  } else {
    printData = printData.replaceAll("<td>Order Type: @@ordertype@@</td>", "");
  }

  // printData = printData.replaceAll("@@csBunitName@@", `${tillData.tillAccess.csBunit.name}`);
  printData = printData.replaceAll("@@csBunitCustomerAddressFulladdress@@", `${fullAddress}`);
  // printData = printData.replaceAll("@@csBunitCustomerAddressCity@@", `${tillData.tillAccess.csBunit.customerAddress.city}`);
  printData = printData.replaceAll("@@csBunitCustomerAddressPhone@@", `${tillData.tillAccess.csBunit.locations[0].phone === null ? "" : tillData.tillAccess.csBunit.locations[0].phone}`);
  printData = printData.replaceAll("@@receiptType@@", `${receiptType}`);
  printData = printData.replaceAll("@@username@@", `${localStorage.getItem("username")}`);
  printData = printData.replaceAll("@@printReciptDataDocumentno@@", `${printReciptData.documentno}`);
  printData = printData.replaceAll("@@date@@", `${new Date().toLocaleString()}`);
  printData = printData.replaceAll("@@printReciptDataCustomerName@@", `${customerName}`);
  printData = printData.replaceAll("@@printReciptDataCustomerMobileNo@@", `${customerMobile === null ? "" : customerMobile}`);
  printData = printData.replaceAll("@@printReciptDataItems@@", `${printItems}`);
  printData = printData.replaceAll("@@printReciptDataItemsLength@@", `${parseFloat(printReciptData.items.length).toFixed(roundOffValue[0].prcPrecision)}`);
  printData = printData.replaceAll("@@printReciptDataTotalQty@@", `${parseFloat(printReciptData.totalQty).toFixed(roundOffValue[0].prcPrecision)}`);
  printData = printData.replaceAll("@@taxAmountBreakup@@", `${taxAmountBreakup}`);
  printData = printData.replaceAll("@@netAmount@@", `${currenciesList[0].isoCode === "USD" ? parseFloat((parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat((parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat((parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat((parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  printData = printData.replaceAll("@@taxAmount@@", `${currenciesList[0].isoCode === "USD" ? parseFloat(printReciptData.tax.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(printReciptData.tax.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat(printReciptData.tax.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") :  currenciesList[0].isoCode === "EUR" ? parseFloat(printReciptData.tax.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat(printReciptData.tax.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(printReciptData.tax.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  printData = printData.replaceAll("@@discountAmount@@", `${currenciesList[0].isoCode === "USD" ? parseFloat((printReciptData?.discount || 0).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData?.discount || 0).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat((printReciptData?.discount || 0).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") :  currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData?.discount || 0).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData?.discount || 0).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData?.discount || 0).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  printData = printData.replaceAll("@@roundOff@@", `${currenciesList[0].isoCode === "USD" ? parseFloat(printReciptData.roundOff.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(printReciptData.roundOff.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat(printReciptData.roundOff.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") :  currenciesList[0].isoCode === "EUR" ? parseFloat(printReciptData.roundOff.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat(printReciptData.roundOff.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(printReciptData.roundOff.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  printData = printData.replaceAll("@@total@@", `${currenciesList[0].isoCode === "USD" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  printData = printData.replaceAll("@@printReciptDataPayments@@", `${printPayments}`);
  printData = printData.replaceAll("@@amountReceived@@", `${currenciesList[0].isoCode === "USD" ? parseFloat((printReciptData.paid - Math.abs(printReciptData.change)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.paid - Math.abs(printReciptData.change)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat((printReciptData.paid - Math.abs(printReciptData.change)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.paid - Math.abs(printReciptData.change)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat((printReciptData.paid - Math.abs(printReciptData.change)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.paid - Math.abs(printReciptData.change)).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  printData = printData.replaceAll("@@cashChangeAmount@@", `${  currenciesList[0].isoCode === "USD" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ?parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") :  currenciesList[0].isoCode === "EUR" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ?parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  printData = printData.replaceAll("@@couponCode@@", `${couponCodeData}`);
  printData = printData.replaceAll("@@billDetails@@", `${billDetailsData}`);
  printData = printData.replaceAll("@@additionalBodyData@@", ``);

  printWindow.document.write(printData);
  const scriptData = document.createElement("script");
  scriptData.innerHTML =
    "Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => { window.print(); window.close(); })";
  printWindow.document.getElementsByTagName("body")[0].appendChild(scriptData);
};
